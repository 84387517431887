@media only screen and (min-width:768px) and (max-width:1000px) {
	.header-container.container{
		max-width:100% !important;
		width:100% !important;
	}
}

@media screen and (max-width:680px) {
	.hide-mob{
		display: none;
	}
	.mobile-margin{
		margin-top: -20px !important;
	}
}

.off-bg{
	background: #F1F1F1;
}
